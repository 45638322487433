var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._g({
    staticClass: "rdtypecard",
    attrs: {
      "ripple": false
    }
  }, _vm.$listeners), [_c('v-img', {
    attrs: {
      "src": _vm.getImage,
      "height": "148",
      "width": "244"
    }
  }), _c('v-card-title', {
    staticClass: "rdtypecard--label py-1 px-4"
  }, [_c('p', {
    staticClass: "text-truncate d-inline-block"
  }, [_vm._v(" " + _vm._s(_vm.getLabel) + " ")])]), _vm.disabled ? _c('div', {
    staticClass: "rdtypecard--disabled"
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }