var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rtextchips"
  }, [_vm._l(_vm.chips, function (chip, i) {
    return _c('r-chip', {
      key: "rtextchips-".concat(i),
      staticClass: "mr-2 my-1 d-inline-block",
      attrs: {
        "label": chip,
        "width": 66,
        "removable": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('remove-chip', {
            value: chip
          });
        }
      }
    });
  }), _c('v-text-field', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "disabled": _vm.disabled,
      "solo": "",
      "dense": "",
      "flat": "",
      "placeholder": _vm.placeholder
    },
    on: {
      "blur": _vm.onBlur
    },
    nativeOn: {
      "keyup": function keyup($event) {
        return _vm.onKeyup.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newChip,
      callback: function callback($$v) {
        _vm.newChip = $$v;
      },
      expression: "newChip"
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }