var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editentitygroup pa-2 pb-8 pl-8"
  }, [_vm.editMode ? _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-text-field', {
    ref: "groupName",
    staticClass: "groupname--input",
    attrs: {
      "rules": [_vm.rules.required, function (v) {
        return _vm.rules.uniqueGroupName(v, _vm.groups);
      }],
      "dense": "",
      "outlined": "",
      "placeholder": "Entity group name"
    },
    on: {
      "blur": _vm.onSetGroupName,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onSetGroupName.apply(null, arguments);
      }
    },
    model: {
      value: _vm.groupName,
      callback: function callback($$v) {
        _vm.groupName = $$v;
      },
      expression: "groupName"
    }
  }), _c('v-btn', {
    staticClass: "delete--button",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.onClickDeleteEntityGroup
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1)], 1) : _c('div', {
    staticClass: "d-flex justify-space-between mb-2 pr-1"
  }, [_c('p', {
    staticClass: "ma-0 mt-1"
  }, [_vm._v(" " + _vm._s(_vm.groupName) + " ")]), _c('div', [_c('v-btn', {
    staticClass: "edit--button",
    attrs: {
      "small": "",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.editMode = true;
      }
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1), _c('v-btn', {
    staticClass: "delete--button",
    attrs: {
      "small": "",
      "icon": ""
    },
    on: {
      "click": _vm.onClickDeleteEntityGroup
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1)], 1)]), _c('r-text-chips', {
    staticClass: "px-1",
    attrs: {
      "disabled": _vm.localEntities.length >= _vm.ENTITYMAPS_LIMITS.ENTITIES_PER_GROUP,
      "chips": _vm.localEntities
    },
    on: {
      "add-chip": _vm.onAddEntity,
      "remove-chip": _vm.onRemoveEntity
    }
  }), _c('r-error-message', {
    key: "editentitygroup--errormessage-".concat(_vm.validationCount),
    attrs: {
      "errors": _vm.validationErrors
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }